


<div *ngIf="service.isOpen | async">
  @switch (service.type){
    @case ('modal'){
      <ng-container *ngTemplateOutlet="modal"></ng-container>
    }
    @case ('alert'){
      <ng-container *ngTemplateOutlet="alert"></ng-container>
    }
    @case ('viewNote'){
      <ng-container *ngTemplateOutlet="viewNote"></ng-container>
    }
    @case ('addNote'){
      <ng-container *ngTemplateOutlet="addNote"></ng-container>
    }
    @default {}
  }
</div>

<!--MODAL-->
<ng-template #modal>
  <div class="modal fade show d-block modal-is-open">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content border-0 border-radius">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5 fw-bold">{{service.param.title}}</h1>
          <img src="assets/icon/x_icon_gray.svg" class="ms-auto bg-light-gray p-2 border-radius pointer" (click)="service.setResult(false); service.setOpen(false)" />
        </div>
        <div class="modal-body border-0">
          {{service.param.text}}
          {{service.resultData | async}}
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <s-button *ngIf="service.param.btnIcon && service.param.btnLabel" [label]="service.param.btnLabel" [icon]="service.param.btnIcon" (click)="service.setResult(true); service.setOpen(false)" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="service.isOpen | async" class="modal-backdrop fade show"></div>
</ng-template>

<!--ALERT-->
<ng-template #alert>
 <div class="container position-relative pointer" (click)="service.setResult(false); service.setOpen(false)">
   <div class="alert" [style.border-left-color]="service.param.type === 'success' ? 'var(--green)' : 'var(--red)'">
     <div class="row align-items-center">
       <div class="col-2 col-md-1">
         <img src="assets/icon/{{service.param.btnIcon}}" class="bg-light-gray p-2 border-radius" />
       </div>
       <div class="col-8 col-md-9">
         <h1 class="modal-title fs-5 fw-bold">{{service.param.title}}</h1>
         <p class="mb-0">{{service.param.text}}</p>
       </div>
       <div class="col-2 text-end">
         <img src="assets/icon/x_icon_gray.svg" class="bg-light-gray p-2 border-radius" />
       </div>
     </div>
   </div>
 </div>
</ng-template>

<!--ADD NOTE-->
<ng-template #addNote>
  <div class="modal fade show d-block modal-is-open">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content border-0 border-radius">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5 fw-bold">{{service.param.title}}</h1>
          <img src="assets/icon/x_icon_gray.svg" class="ms-auto bg-light-gray p-2 border-radius pointer" (click)="service.setResult(false); service.setOpen(false)" />
        </div>
        <div class="modal-body border-0">
          <a-add-note [nota]="service.param.text" (value)="data = $event" />
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <s-button [label]="service.param.btnLabel" [icon]="service.param.btnIcon" (click)="service.setResult(true); service.setResultData(data); service.setOpen(false)" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="service.isOpen | async" class="modal-backdrop fade show"></div>
</ng-template>

<!--VIEW NOTE-->
<ng-template #viewNote>
  <div class="modal fade show d-block modal-is-open">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content border-0 border-radius">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5 fw-bold">{{service.param.title}}</h1>
          <img src="assets/icon/x_icon_gray.svg" class="ms-auto bg-light-gray p-2 border-radius pointer" (click)="service.setOpen(false)" />
        </div>
        <div class="modal-body border-0">
          <a-view-note [nota]="service.param.text" [notaId]="service.notaId!" (action)="service.setOpen(false); service.setResultData($event)" />
        </div>
        <div class="modal-footer border-0 justify-content-center"></div>
      </div>
    </div>
  </div>
  <div *ngIf="service.isOpen | async" class="modal-backdrop fade show"></div>
</ng-template>
