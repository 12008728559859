import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import {urlRoutes} from "../app.routes";
import {AlertParam} from "../models/ModalParam";
import {AlertService} from "../services/alert.service";
import { inject } from '@angular/core';
import { Router } from '@angular/router';

function handleAuthError(err: HttpErrorResponse, alertService: AlertService, routerService: Router): Observable<any> {
  if (err.status === 401 || err.status === 403) {
    alertService.alert(new AlertParam('Ops, c\'è stato un errore', `La tua sessione è scaduta o non sei autorizzato`, 'error'));
    routerService.navigateByUrl(urlRoutes.LOGIN)
    return of(err.message);
  }
  if(err.status === 500){
    alertService.alert(new AlertParam('Ops, c\'è stato un errore', err.message, 'error'));
  }
return throwError(err);
}

export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const alertService = inject(AlertService);
  const router = inject(Router);
  return next(req).pipe(catchError(x=> handleAuthError(x, alertService, router)))
}
