import {ChangeDetectionStrategy, Component, ElementRef, HostListener, inject, viewChild} from '@angular/core';
import {AlertService} from "../../../services/alert.service";
import {ButtonComponent} from "../button.component";
import {AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {AddNoteComponent} from "../../admin/add-note/add-note.component";
import {ViewNoteComponent} from "../../admin/view-note/view-note.component";

@Component({
  selector: 's-alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgClass, NgIf, NgTemplateOutlet, ButtonComponent, AddNoteComponent, ViewNoteComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.css'
})
export class AlertComponent {
  service = inject(AlertService);
  data: any;
  classContainerModal = 'modal-is-open';

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if(this.service.isOpen.getValue()){
      const target = event.target as HTMLElement
      if(target.classList.contains(this.classContainerModal)){
        this.service.setOpen(false);
      }
    }
  }
}
