import {HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export function tokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  let reqCloned = req.clone();
  const token = localStorage.getItem('TOKEN');
  if(token){
    reqCloned = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token)
    })
  }
  return next(reqCloned);
}
