import * as i0 from '@angular/core';
import { InjectionToken, APP_INITIALIZER, PLATFORM_ID, isDevMode, inject, Injectable, Inject, APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, CommonModule } from '@angular/common';
import * as i2 from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { filter, skipWhile } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
var TRACK_EVENT;
(function (TRACK_EVENT) {
  TRACK_EVENT["PAGE_VIEW"] = "trackPageView";
  TRACK_EVENT["CUSTOM_EVENT"] = "trackEvent";
  TRACK_EVENT["GOAL"] = "trackGoal";
  TRACK_EVENT["SEARCH"] = "trackSiteSearch";
  TRACK_EVENT["ADD_ECOMMERCE_ITEM"] = "addEcommerceItem";
  TRACK_EVENT["REMOVE_ECOMMERCE_ITEM"] = "removeEcommerceItem";
  TRACK_EVENT["CLEAR_ECOMMERCE_CART"] = "clearEcommerceCart";
  TRACK_EVENT["SET_ECOMMERCE_VIEW"] = "setEcommerceView";
  TRACK_EVENT["UPDATE_ECOMMERCE_CART"] = "trackEcommerceCartUpdate";
  TRACK_EVENT["ORDER_ECOMMERCE"] = "trackEcommerceOrder";
  TRACK_EVENT["ECOMMERCE_ADD_TO_CART"] = "ecommerceAddToCart";
  TRACK_EVENT["ECOMMERCE_REMOVE_FROM_CART"] = "ecommerceRemoveFromCart";
  TRACK_EVENT["ECOMMERCE_PRODUCT_DETAIL_VIEW"] = "ecommerceProductDetailView";
  TRACK_EVENT["ECOMMERCE_CART_UPDATE"] = "ecommerceCartUpdate";
  TRACK_EVENT["ECOMMERCE_ORDER"] = "ecommerceOrder";
  TRACK_EVENT["SET_CUSTOM_VARIABLE"] = "setCustomVariable";
  TRACK_EVENT["DELETE_CUSTOM_VARIABLE"] = "deleteCustomVariable";
  TRACK_EVENT["STORE_CUSTOM_VARIABLES_IN_COOKIE"] = "storeCustomVariablesInCookie";
  TRACK_EVENT["SET_CUSTOM_DIMENSION_VALUE"] = "setCustomDimensionValue";
  TRACK_EVENT["DELETE_CUSTOM_DIMENSION"] = "deleteCustomDimension";
  TRACK_EVENT["SET_CUSTOM_DIMENSION"] = "setCustomDimension";
  TRACK_EVENT["ALL_CONTENT_IMPRESSIONS"] = "trackAllContentImpressions";
  TRACK_EVENT["VISIBLE_CONTENT_IMPRESSIONS"] = "trackVisibleContentImpressions";
  TRACK_EVENT["CONTENT_IMPRESSIONS_WITH_NODE"] = "trackContentImpressionsWithinNode";
  TRACK_EVENT["CONTENT_IMPRESSION"] = "trackContentImpression";
  TRACK_EVENT["LOG_ALL_CONTENT_BLOCKS_ON_PAGE"] = "logAllContentBlocksOnPage";
  TRACK_EVENT["CONTENT_INTERACTION_NODE"] = "trackContentInteractionNode";
  TRACK_EVENT["CONTENT_INTERACTION"] = "trackContentInteraction";
  TRACK_EVENT["LINK"] = "trackLink";
  TRACK_EVENT["ENABLE_LINK_TRACKING"] = "enableLinkTracking";
  TRACK_EVENT["SET_IGNORE_CLASSES"] = "setIgnoreClasses";
  TRACK_EVENT["SET_LINK_CLASSES"] = "setLinkClasses";
  TRACK_EVENT["SET_DOWNLOAD_CLASSES"] = "setDownloadClasses";
  TRACK_EVENT["SET_DOWNLOAD_EXTENSIONS"] = "setDownloadExtensions";
  TRACK_EVENT["ADD_DOWNLOAD_EXTENSIONS"] = "addDownloadExtensions";
  TRACK_EVENT["REMOVE_DOWNLOAD_EXTENSIONS"] = "removeDownloadExtensions";
  TRACK_EVENT["SET_LINK_TRACKING_TIMER"] = "setLinkTrackingTimer";
  TRACK_EVENT["SET_USER_ID"] = "setUserId";
  TRACK_EVENT["RESET_USER_ID"] = "resetUserId";
  TRACK_EVENT["SET_USER_IS_ANONYMOUS"] = "setUserIsAnonymous";
  TRACK_EVENT["DEANONYMIZE_USER"] = "deanonymizeUser";
  TRACK_EVENT["SET_SESSION_ID_STRICT_PRIVACY_MODE"] = "setSessionIdStrictPrivacyMode";
  TRACK_EVENT["DISABLE_COOKIES"] = "disableCookies";
  TRACK_EVENT["ENABLE_COOKIES"] = "enableCookies";
  TRACK_EVENT["DELETE_COOKIES"] = "deleteCookies";
  TRACK_EVENT["SET_COOKIE_NAME_PREFIX"] = "setCookieNamePrefix";
  TRACK_EVENT["SET_COOKIE_DOMAIN"] = "setCookieDomain";
  TRACK_EVENT["SET_COOKIE_PATH"] = "setCookiePath";
  TRACK_EVENT["SET_SECURE_COOKIE"] = "setSecureCookie";
  TRACK_EVENT["SET_VISITOR_COOKIE_TIMEOUT"] = "setVisitorCookieTimeout";
  TRACK_EVENT["SET_SESSION_COOKIE_TIMEOUT"] = "setSessionCookieTimeout";
  TRACK_EVENT["SET_VISITOR_ID_COOKIE"] = "setVisitorIdCookie";
  TRACK_EVENT["SET_DOCUMENT_TITLE"] = "setDocumentTitle";
  TRACK_EVENT["SET_DOMAINS"] = "setDomains";
  TRACK_EVENT["SET_CUSTOM_URL"] = "setCustomUrl";
  TRACK_EVENT["SET_REFERRER_URL"] = "setReferrerUrl";
  TRACK_EVENT["SET_API_URL"] = "setApiUrl";
  TRACK_EVENT["GET_PIWIK_URL"] = "getPiwikUrl";
  TRACK_EVENT["GET_CURRENT_URL"] = "getCurrentUrl";
  TRACK_EVENT["DISCARD_HASH_TAG"] = "discardHashTag";
  TRACK_EVENT["SET_GENERATION_TIME_MS"] = "setGenerationTimeMs";
  TRACK_EVENT["APPEND_TO_TRACKING_URL"] = "appendToTrackingUrl";
  TRACK_EVENT["SET_DO_NOT_TRACK"] = "setDoNotTrack";
  TRACK_EVENT["KILL_FRAME"] = "killFrame";
  TRACK_EVENT["REDIRECT_FILE"] = "redirectFile";
  TRACK_EVENT["SET_CAMPAIGN_NAME_KEY"] = "setCampaignNameKey";
  TRACK_EVENT["SET_CAMPAIGN_KEYWORD_KEY"] = "setCampaignKeywordKey";
  TRACK_EVENT["PING"] = "ping";
  TRACK_EVENT["ADD_LISTENER"] = "addListener";
  TRACK_EVENT["SET_REQUEST_METHOD"] = "setRequestMethod";
  TRACK_EVENT["SET_CUSTOM_REQUEST_PROCESSING"] = "setCustomRequestProcessing";
  TRACK_EVENT["SET_REQUEST_CONTENT_TYPE"] = "setRequestContentType";
  TRACK_EVENT["ENABLE_JS_ERROR_TRACKING"] = "enableJSErrorTracking";
  TRACK_EVENT["ERROR"] = "trackError";
  TRACK_EVENT["SET_TRACKING_SOURCE"] = "setTrackingSource";
})(TRACK_EVENT || (TRACK_EVENT = {}));
var CustomVariableScope;
(function (CustomVariableScope) {
  CustomVariableScope["VISIT"] = "visit";
  CustomVariableScope["PAGE"] = "page";
})(CustomVariableScope || (CustomVariableScope = {}));

/**
 * Provide a Injection Token to global settings.
 */
const NGX_PIWIK_PRO_SETTINGS_TOKEN = new InjectionToken('ngx-piwik-pro-settings', {
  factory: () => ({
    containerId: '',
    containerURL: ''
  })
});
const NGX_PIWIK_PRO_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: PiwikProInitializer,
  deps: [NGX_PIWIK_PRO_SETTINGS_TOKEN, DOCUMENT, PLATFORM_ID]
};
function PiwikProInitializer(settings, document, platformId) {
  if (window) {
    window._paq = window._paq || [];
  }
  return async () => {
    if (!isPlatformBrowser(platformId)) {
      return;
    }
    if (!settings.containerId) {
      if (!isDevMode()) {
        console.error('Empty tracking code for Piwik Pro. Make sure to provide one when initializing NgxPiwikProModule.');
      }
      return;
    }
    if (!settings.containerURL) {
      if (!isDevMode()) {
        console.error('Empty tracking URL for Piwik Pro. Make sure to provide one when initializing NgxPiwikProModule.');
      }
      return;
    }
    if (!document) {
      if (!isDevMode()) {
        console.error('Was not possible to access Document interface. Make sure this module is running on a Browser w/ access do Document interface.');
      }
    }
    const s = document.createElement('script');
    s.async = true;
    if (settings.nonce) {
      s.setAttribute("nonce", settings.nonce);
    }
    s.text = `(function(window, document, dataLayerName, id) {
      window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
      function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
      var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
      var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
      tags.async=!0,tags.src="${settings.containerURL}/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
      !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
      })(window, document, 'dataLayer', '${settings.containerId}')`;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  };
}
const NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN = new InjectionToken('ngx-piwik-pro-routing-settings', {
  factory: () => ({})
});
const NGX_WINDOW = new InjectionToken("ngx-window", {
  providedIn: "root",
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error("Window is not available");
    }
    return defaultView;
  }
});
class PaqService {
  constructor(titleService, routerService, _window) {
    this.titleService = titleService;
    this.routerService = routerService;
    this._window = _window;
  }
  push(collection) {
    if (!this._window._paq) {
      throw new Error("_paq is not available");
    }
    this._window._paq.push(["setCustomUrl", this.routerService.url]);
    this._window._paq.push(["setDocumentTitle", this.titleService.getTitle()]);
    return this._window._paq.push(collection);
  }
  static {
    this.ɵfac = function PaqService_Factory(t) {
      return new (t || PaqService)(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.Router), i0.ɵɵinject(NGX_WINDOW));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PaqService,
      factory: PaqService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaqService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Title
    }, {
      type: i2.Router
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_WINDOW]
      }]
    }];
  }, null);
})();
class PageViewsService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackPageView(customPageTitle) {
    this.paqService.push([TRACK_EVENT.PAGE_VIEW, ...(customPageTitle ? [customPageTitle] : [])]);
  }
  static {
    this.ɵfac = function PageViewsService_Factory(t) {
      return new (t || PageViewsService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PageViewsService,
      factory: PageViewsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PageViewsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
const NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: PiwikProRouterInitializer,
  deps: [NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN, Title, PageViewsService]
};
function PiwikProRouterInitializer(settings, titleService, pageViewsService) {
  return async c => {
    const router = c.injector.get(Router);
    const {
      include = [],
      exclude = [],
      skipFirstPageView
    } = settings ?? {};
    const includeRules = normalizePathRules(include);
    const excludeRules = normalizePathRules(exclude);
    const subs = router.events.pipe(filter(event => event instanceof NavigationEnd), skipWhile((_, index) => {
      // Preventing double views on the first trigger (because PiwikPro Already send one ping on setup by default)
      if (skipFirstPageView) {
        return index === 0;
      }
      return false;
    }), filter(event => includeRules.length > 0 ? includeRules.some(rule => rule.test(event.urlAfterRedirects)) : true), filter(event => excludeRules.length > 0 ? !excludeRules.some(rule => rule.test(event.urlAfterRedirects)) : true)).subscribe(() => {
      pageViewsService.trackPageView();
    });
    // Cleanup
    c.onDestroy(() => subs.unsubscribe());
  };
}
/** Converts all path rules from string to Regex instances */
function normalizePathRules(rules) {
  return rules.map(rule => rule instanceof RegExp ? rule : new RegExp(`^${rule.replace('*', '.*')}$`, 'i'));
}
class ContentTrackingService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackAllContentImpressions() {
    this.paqService.push([TRACK_EVENT.ALL_CONTENT_IMPRESSIONS]);
  }
  trackVisibleContentImpressions(checkOnScroll, watchIterval) {
    this.paqService.push([TRACK_EVENT.VISIBLE_CONTENT_IMPRESSIONS, checkOnScroll, watchIterval]);
  }
  trackContentImpressionsWithinNode(domNode) {
    this.paqService.push([TRACK_EVENT.CONTENT_IMPRESSIONS_WITH_NODE, domNode]);
  }
  trackContentImpression(contentName, contentPiece, contentTarget) {
    this.paqService.push([TRACK_EVENT.CONTENT_IMPRESSION, contentName, contentPiece, contentTarget]);
  }
  logAllContentBlocksOnPage() {
    this.paqService.push([TRACK_EVENT.LOG_ALL_CONTENT_BLOCKS_ON_PAGE]);
  }
  trackContentInteractionNode(domNode, contentInteraction) {
    this.paqService.push([TRACK_EVENT.CONTENT_INTERACTION_NODE, domNode, contentInteraction]);
  }
  trackContentInteraction(contentInteraction, contentName, contentPiece, contentTarget) {
    this.paqService.push([TRACK_EVENT.CONTENT_INTERACTION, contentInteraction, contentName, contentPiece, contentTarget]);
  }
  static {
    this.ɵfac = function ContentTrackingService_Factory(t) {
      return new (t || ContentTrackingService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContentTrackingService,
      factory: ContentTrackingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentTrackingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class CookieManagementService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  disableCookies() {
    this.paqService.push([TRACK_EVENT.DISABLE_COOKIES]);
  }
  enableCookies() {
    this.paqService.push([TRACK_EVENT.ENABLE_COOKIES]);
  }
  deleteCookies() {
    this.paqService.push([TRACK_EVENT.DELETE_COOKIES]);
  }
  hasCookies() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.hasCookies());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  setCookieNamePrefix(prefix) {
    this.paqService.push([TRACK_EVENT.SET_COOKIE_NAME_PREFIX, prefix]);
  }
  setCookieDomain(domain) {
    this.paqService.push([TRACK_EVENT.SET_COOKIE_DOMAIN, domain]);
  }
  setCookiePath(path) {
    this.paqService.push([TRACK_EVENT.SET_COOKIE_PATH, path]);
  }
  setSecureCookie(secure) {
    this.paqService.push([TRACK_EVENT.SET_SECURE_COOKIE, secure]);
  }
  setVisitorCookieTimeout(seconds) {
    this.paqService.push([TRACK_EVENT.SET_VISITOR_COOKIE_TIMEOUT, seconds]);
  }
  setSessionCookieTimeout(seconds) {
    this.paqService.push([TRACK_EVENT.SET_SESSION_COOKIE_TIMEOUT, seconds]);
  }
  setVisitorIdCookie() {
    this.paqService.push([TRACK_EVENT.SET_VISITOR_ID_COOKIE]);
  }
  static {
    this.ɵfac = function CookieManagementService_Factory(t) {
      return new (t || CookieManagementService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CookieManagementService,
      factory: CookieManagementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieManagementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class CustomDimensionsService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  setCustomDimensionValue(customDimensionId, customDimensionValue) {
    this.paqService.push(['setCustomDimensionValue', customDimensionId, customDimensionValue]);
  }
  deleteCustomDimension(customDimensionId) {
    this.paqService.push(['deleteCustomDimension', customDimensionId]);
  }
  getCustomDimensionValue(customDimensionId) {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getCustomDimensionValue(customDimensionId));
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  static {
    this.ɵfac = function CustomDimensionsService_Factory(t) {
      return new (t || CustomDimensionsService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomDimensionsService,
      factory: CustomDimensionsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomDimensionsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class SiteSearchService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackSiteSearch(keyword, category, searchCount, dimensions) {
    this.paqService.push([TRACK_EVENT.SEARCH, keyword, category, searchCount, dimensions]);
  }
  static {
    this.ɵfac = function SiteSearchService_Factory(t) {
      return new (t || SiteSearchService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SiteSearchService,
      factory: SiteSearchService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SiteSearchService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class CustomEventsService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackEvent(category, action, name, value) {
    const eventArguments = [category, action, ...(name ? [name] : []), ...(name ? [value] : [])];
    this.paqService.push([TRACK_EVENT.CUSTOM_EVENT, ...eventArguments]);
  }
  static {
    this.ɵfac = function CustomEventsService_Factory(t) {
      return new (t || CustomEventsService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomEventsService,
      factory: CustomEventsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomEventsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class DownloadAndOutlinkService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackLink(url, linkType, customData, callback) {
    this.paqService.push([TRACK_EVENT.LINK, url, linkType, customData, callback]);
  }
  enableLinkTracking(enable) {
    this.paqService.push([TRACK_EVENT.ENABLE_LINK_TRACKING, enable]);
  }
  setLinkClasses(classes) {
    this.paqService.push([TRACK_EVENT.SET_LINK_CLASSES, classes]);
  }
  setDownloadClasses(classes) {
    this.paqService.push([TRACK_EVENT.SET_DOWNLOAD_CLASSES, classes]);
  }
  setDownloadExtensions(extensions) {
    this.paqService.push([TRACK_EVENT.SET_DOWNLOAD_EXTENSIONS, extensions]);
  }
  addDownloadExtensions(extensions) {
    this.paqService.push([TRACK_EVENT.ADD_DOWNLOAD_EXTENSIONS, extensions]);
  }
  removeDownloadExtensions(extensions) {
    this.paqService.push([TRACK_EVENT.REMOVE_DOWNLOAD_EXTENSIONS, extensions]);
  }
  setLinkTrackingTimer(time) {
    this.paqService.push([TRACK_EVENT.SET_LINK_TRACKING_TIMER, time]);
  }
  getLinkTrackingTimer() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getLinkTrackingTimer());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  setIgnoreClasses(classes) {
    this.paqService.push([TRACK_EVENT.SET_IGNORE_CLASSES, classes]);
  }
  static {
    this.ɵfac = function DownloadAndOutlinkService_Factory(t) {
      return new (t || DownloadAndOutlinkService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DownloadAndOutlinkService,
      factory: DownloadAndOutlinkService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DownloadAndOutlinkService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class ECommerceService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  /**
   * @deprecated Please use the ecommerceAddToCart instead.
   */
  addEcommerceItem(productSKU, productName, productCategory, productPrice, productQuantity) {
    this.paqService.push([TRACK_EVENT.ADD_ECOMMERCE_ITEM, productSKU, productName, productCategory, productPrice, productQuantity]);
  }
  ecommerceAddToCart(products) {
    this.paqService.push([TRACK_EVENT.ECOMMERCE_ADD_TO_CART, products]);
  }
  /**
   * @deprecated Please use the ecommerceRemoveFromCart instead.
   */
  removeEcommerceItem(productSKU) {
    this.paqService.push([TRACK_EVENT.REMOVE_ECOMMERCE_ITEM, productSKU]);
  }
  ecommerceRemoveFromCart(products) {
    this.paqService.push([TRACK_EVENT.ECOMMERCE_REMOVE_FROM_CART, products]);
  }
  /**
   * @deprecated
   */
  clearEcommerceCart() {
    this.paqService.push([TRACK_EVENT.CLEAR_ECOMMERCE_CART]);
  }
  /**
   * @deprecated
   */
  getEcommerceItems() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getEcommerceItems());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  /**
   * @deprecated Please use the ecommerceOrder instead.
   */
  trackEcommerceOrder(orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount) {
    this.paqService.push([TRACK_EVENT.ORDER_ECOMMERCE, orderId, orderGrandTotal, orderSubTotal, orderTax, orderShipping, orderDiscount]);
  }
  ecommerceOrder(products, paymentInformation) {
    this.paqService.push([TRACK_EVENT.ECOMMERCE_ORDER, products, paymentInformation]);
  }
  /**
   * @deprecated Please use the ecommerceCartUpdate instead.
   */
  trackEcommerceCartUpdate(cartAmount) {
    this.paqService.push([TRACK_EVENT.UPDATE_ECOMMERCE_CART, cartAmount]);
  }
  ecommerceCartUpdate(products, grandTotal) {
    this.paqService.push([TRACK_EVENT.ECOMMERCE_CART_UPDATE, products, grandTotal]);
  }
  /**
   * @deprecated
   */
  setEcommerceView(productSKU, productName, productCategory, productPrice) {
    this.paqService.push([TRACK_EVENT.SET_ECOMMERCE_VIEW, productSKU, productName, productCategory, productPrice]);
  }
  ecommerceProductDetailView(products) {
    this.paqService.push([TRACK_EVENT.ECOMMERCE_PRODUCT_DETAIL_VIEW, products]);
  }
  static {
    this.ɵfac = function ECommerceService_Factory(t) {
      return new (t || ECommerceService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ECommerceService,
      factory: ECommerceService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ECommerceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class GoalConversionsService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  trackGoal(goalId, conversionValue, dimensions) {
    this.paqService.push([TRACK_EVENT.GOAL, goalId, conversionValue, ...(dimensions ? [{
      ...dimensions
    }] : [])]);
  }
  static {
    this.ɵfac = function GoalConversionsService_Factory(t) {
      return new (t || GoalConversionsService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoalConversionsService,
      factory: GoalConversionsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoalConversionsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class UserManagementService {
  constructor(paqService) {
    this.paqService = paqService;
  }
  getUserId() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getUserId());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  setUserId(userId) {
    this.paqService.push([TRACK_EVENT.SET_USER_ID, userId]);
  }
  resetUserId() {
    this.paqService.push([TRACK_EVENT.RESET_USER_ID]);
  }
  getVisitorId() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getVisitorId());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  getVisitorInfo() {
    return new Promise((resolve, reject) => {
      try {
        this.paqService.push([function () {
          resolve(this.getVisitorInfo());
        }]);
      } catch (e) {
        if (e instanceof ReferenceError) {
          reject(e);
        }
      }
    });
  }
  static {
    this.ɵfac = function UserManagementService_Factory(t) {
      return new (t || UserManagementService)(i0.ɵɵinject(PaqService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserManagementService,
      factory: UserManagementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserManagementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PaqService
    }];
  }, null);
})();
class DataLayerService {
  constructor(_window) {
    this._window = _window;
  }
  push(data) {
    return this._window.dataLayer.push(data);
  }
  static {
    this.ɵfac = function DataLayerService_Factory(t) {
      return new (t || DataLayerService)(i0.ɵɵinject(NGX_WINDOW));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataLayerService,
      factory: DataLayerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataLayerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_WINDOW]
      }]
    }];
  }, null);
})();

/**
 * Check if there is some global function called _paq on Window object, or create an empty function to doesn't brake codes...
 */
function getPaqFn(window) {
  return window ? window['_paq'] = window['_paq'] || [] : null;
}
/**
 * Provides an injection token to access Piwik Pro Paq
 */
const NGX_PAQ = new InjectionToken('ngx-paq', {
  providedIn: 'root',
  factory: () => getPaqFn(inject(NGX_WINDOW))
});
class NgxPiwikProModule {
  constructor() {}
  static forRoot(containerId, containerURL, nonce) {
    return {
      ngModule: NgxPiwikProModule,
      providers: [{
        provide: NGX_PIWIK_PRO_SETTINGS_TOKEN,
        useValue: {
          containerId,
          containerURL,
          nonce
        }
      }, NGX_PIWIK_PRO_INITIALIZER_PROVIDER]
    };
  }
  static {
    this.ɵfac = function NgxPiwikProModule_Factory(t) {
      return new (t || NgxPiwikProModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPiwikProModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPiwikProModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], function () {
    return [];
  }, null);
})();
class NgxPiwikProRouterModule {
  static forRoot(settings) {
    return {
      ngModule: NgxPiwikProRouterModule,
      providers: [{
        provide: NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN,
        useValue: settings ?? {
          skipFirstPageView: true
        }
      }]
    };
  }
  static {
    this.ɵfac = function NgxPiwikProRouterModule_Factory(t) {
      return new (t || NgxPiwikProRouterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPiwikProRouterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER],
      imports: [CommonModule, NgxPiwikProModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPiwikProRouterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NgxPiwikProModule],
      providers: [NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER],
      declarations: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-piwik-pro
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ContentTrackingService, CookieManagementService, CustomDimensionsService, CustomEventsService, CustomVariableScope, DataLayerService, DownloadAndOutlinkService, ECommerceService, GoalConversionsService, NGX_PAQ, NGX_PIWIK_PRO_INITIALIZER_PROVIDER, NGX_PIWIK_PRO_ROUTER_INITIALIZER_PROVIDER, NGX_PIWIK_PRO_ROUTING_SETTINGS_TOKEN, NGX_PIWIK_PRO_SETTINGS_TOKEN, NGX_WINDOW, NgxPiwikProModule, NgxPiwikProRouterModule, PageViewsService, PaqService, PiwikProInitializer, PiwikProRouterInitializer, SiteSearchService, TRACK_EVENT, UserManagementService, getPaqFn };
