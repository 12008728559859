import {ChangeDetectionStrategy, Component, EventEmitter, input, OnInit, Output} from '@angular/core';
import {TextareaComponent} from "../../shared/textarea.component";
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'a-add-note',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextareaComponent
  ],
  templateUrl: './add-note.component.html',
  styleUrl: './add-note.component.css'
})
export class AddNoteComponent implements OnInit{
  nota = input<string>('');
  noteForm = new FormGroup({
    note: new FormControl<string>(''),
  })
  @Output() value = new EventEmitter<string>()

  ngOnInit(){
    if(this.nota()){
      this.noteForm.setValue({note: this.nota()})
      this.value.emit(this.nota());
    }

    this.noteForm.valueChanges.subscribe({
      next: (form) => {
        if(form.note)
          this.value.emit(form.note)
      }
    })

  }
}
