import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {InMemoryScrollingOptions, provideRouter, withInMemoryScrolling, withViewTransitions} from '@angular/router';
import { routes, underConstructionRoute } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {tokenInterceptor} from "./helpers/tokenInterceptor";
import {errorInterceptor} from "./helpers/error.interceptor";
import {environment} from "../environments/environment";
import {NgxPiwikProModule} from "@piwikpro/ngx-piwik-pro";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top'
};

if(environment.underCostruction){
  var route = underConstructionRoute;
} else {
  var route = routes;
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_SITE_KEY },
    provideRouter(route, withViewTransitions(), withInMemoryScrolling(scrollConfig)),
    provideHttpClient(
      withInterceptors([tokenInterceptor, errorInterceptor])
    ),
    importProvidersFrom(
      environment.production ?
          NgxPiwikProModule.forRoot('ae2d5926-83e6-4404-8e33-31781175e637', 'https://diamond.piwik.pro') :
          []
    )
  ]
};
